import React from 'react';
import { graphql } from 'gatsby';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Blog';
import Container from 'components/Container';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

import {
  Content,
  FooterNewsletter,
  Hero,
  SidebarArticles,
  SidebarNewsletter,
  SimilarStories,
} from 'views/BlogArticle/components';

export default function BlogArticleTemplate ({ data, pageContext}) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  
  const {
    title,
    author,
    readTime,
    quote,
    date,
    description,
    body,
    heroImage,
  } = data.contentfulBlog;

  const { url } = pageContext;
  const { html } = body.childMarkdownRemark;
  const image = `https:${heroImage.fluid.src}`

  return (
    <Main colorInvert={true}>
      <Box>
        <Hero
          image={image}
          date={date}
          author={author}
          title={title}
          readTime={readTime}
        />
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Box>
                <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                  <Typography
                    variant={'h4'}
                    gutterBottom
                    color={'primary'}
                    align={'center'}
                    fontWeight={'normal'}
                  >
                    "{quote}"
                  </Typography>
                  <Typography gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                  </Typography>
                </Box>
                <Box paddingY={4}>
                  <Divider />
                </Box>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  flexWrap={'wrap'}
                >
                  <Box display={'flex'} alignItems={'center'}>
                    <Avatar sx={{ width: 50, height: 50, marginRight: 2 }}> 
                      { author && author.charAt(0) }
                    </Avatar>
                    <Box>
                      <Typography fontWeight={600}>{author}</Typography>
                      <Typography color={'text.secondary'}>{date}</Typography>
                    </Box>
                  </Box>
                  <Box display={'flex'} alignItems={'center'}>
                    <Typography color={'text.secondary'}>Share:</Typography>
                    <Box marginLeft={0.5}>
                      <IconButton aria-label="Facebook">
                        <FacebookIcon />
                      </IconButton>
                      <IconButton aria-label="Instagram">
                        <InstagramIcon />
                      </IconButton>
                      <IconButton aria-label="Twitter">
                        <TwitterIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xs={12} md={4}>
              {isMd ? (
                <Box marginBottom={4}>
                  <SidebarArticles />
                </Box>
              ) : null}
              <SidebarNewsletter />
            </Grid> */}
          </Grid>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.alternate.main}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      <Box bgcolor={'alternate.main'}>
        <Container>
          {/* <SimilarStories /> */}
        </Container>
        <Container>
          <FooterNewsletter />
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

export const blogQuery = graphql`
  query($id: String!) {
    contentfulBlog(id: { eq: $id }) {
      title
      author
      date
      readTime
      quote
      description {
        description
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid {
          src
        }
      }
    }
  }`;