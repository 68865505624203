import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@mui/material/Link';
import LogoLight from 'images/northwoods-logo-light.png';
import Logo from 'images/northwoods-logo-main.png';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import { NavItem } from './components';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSidebarOpen: () => void;
  pages: {
    company: Array<PageItem>;
    blog: Array<PageItem>;
  };
  colorInvert?: boolean;
}

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }: Props): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    company: companyPages,
    blog: blogPages,
  } = pages;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  const navLinks = [
    { displayText: "Home", link: "#" },
    { displayText: "Services", link: "#services" },
    { displayText: "About Us", link: "#aboutUs" },
    { displayText: "Testimonials", link: "#testimonials" },
    // { displayText: "Blog", link: "#blog" },
  ]

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="Northwoods REI"
        width={{ xs: 100, md: 200 }}
      >
        <Box
          component={'img'}
          src={trigger ? Logo : LogoLight}
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box>
          <Link
            underline="none"
            href="/"
            color={trigger ? "text.primary" : "text.white"}
            key="home"
          >
            Home
          </Link>
        </Box>
        <Box marginLeft={4}>
          <Link
            underline="none"
            href="#services"
            color={trigger ? "text.primary" : "text.white"}
            key="services"
          >
            Services
          </Link>
        </Box>
        <Box marginLeft={4}>
          <Link
            underline="none"
            href="#about"
            color={trigger ? "text.primary" : "text.white"}
            key="about"
          >
            About
          </Link>
        </Box>
        <Box marginLeft={4}>
          <Link
            underline="none"
            href="#analytics"
            color={trigger ? "text.primary" : "text.white"}
            key="whyUs"
          >
            Why us
          </Link>
        </Box>
        <Box marginLeft={4}>
          <Link
            underline="none"
            href="/blog"
            color={trigger ? "text.primary" : "text.white"}
            key="blog"
          >
            Blog
          </Link>
        </Box>
        {/* <Box marginLeft={4}>
          <NavItem
            title={'Account'}
            id={'account-pages'}
            items={accountPages}
            colorInvert={colorInvert}
          />
        </Box> */}
        {/* <Box marginLeft={4}>
          <NavItem
            title={'Pages'}
            id={'secondary-pages'}
            items={secondaryPages}
            colorInvert={colorInvert}
          />
        </Box> */}
        {/* <Box marginLeft={4}>
          <NavItem
            title={'Blog'}
            id={'blog-pages'}
            items={blogPages}
            colorInvert={colorInvert}
          />
        </Box> */}
        {/* <Box marginLeft={4}>
          <NavItem
            title={'Portfolio'}
            id={'portfolio-pages'}
            items={portfolioPages}
            colorInvert={colorInvert}
          />
        </Box> */}
        {/* <Box marginLeft={4}>
          <Button
            variant="contained"
            color="primary"
            component="a"
            target="blank"
            href="https://MUI.com/store/items/the-front-landing-page/"
            size="large"
          >
            Buy now
          </Button>
        </Box> */}
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Topbar;
